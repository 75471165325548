import React, { useRef } from 'react'
import { login, isAuthenticated } from '../utils/auth'
import { Pane, Table, Button, TextInputField } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import {
  navStyles,
  customListStyles,
  tableSectionStyles,
  generalStyles
} from '../styles/sharedComponentStyles'
import CreateSite from '../components/CreateSite'
import SiteDetails from '../components/SiteDetails'
import { useGlobalStore } from '../hooks/useGlobalStore'
import LoadingSpinner from '../components/LoadingSpinner'
import { useRoleAccess } from '../hooks/useRoleAccess'
import _ from 'lodash'
import { useHandleSitesList } from '../hooks/useHandleSitesList'

const useSharedStyles = createUseStyles({
  ...generalStyles,
  ...navStyles,
  ...customListStyles,
  ...tableSectionStyles,
  elementSelector: {
    composes: '$tableCell',
    cursor: 'pointer'
  },
  tableHeaderTitle: {
    textTransform: 'none'
  }
})

const SitesPage = () => {
  const { realmId } = useGlobalStore()
  const { canEditSite, canOpenUserDetails } = useRoleAccess()
  const sharedClasses = useSharedStyles()
  const sitesSectionRef = useRef(null)

  const {
    loading,
    filterValue,
    sitesSet,
    showCreateSiteDialog,
    selectedSite,
    showSiteDetails,
    handleSiteFilter,
    onSiteCreation,
    handleCreateSiteCloseModal,
    handleCreateSiteOpenModal,
    handleOpenSideSheet,
    handleSiteDetailsClose
  } = useHandleSitesList({ sitesSectionRef })

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <nav className={sharedClasses.nav}>
        <Pane className={sharedClasses.secondaryNav} flexWrap="wrap">
          <Pane flex={1}>
            <h1>Sites</h1>
          </Pane>
          <Pane
            className={sharedClasses.autoComplete}
            display="flex"
            marginRight="20px"
          >
            <TextInputField
              value={filterValue}
              label="Search for a site name"
              onChange={e => handleSiteFilter(e.target.value)}
              style={{
                padding: '20px'
              }}
            />
          </Pane>
          {canEditSite && realmId && (
            <Button
              className={sharedClasses.buttonStyles}
              onClick={handleCreateSiteOpenModal}
            >
              Create New Site
            </Button>
          )}
        </Pane>
      </nav>
      {!loading && sitesSet.length <= 0 && (
        <Pane
          display="flex"
          padding={30}
          justifyContent="center"
          alignItems="center"
        >
          <h2>Sorry, there are no sites for this realm.</h2>
        </Pane>
      )}
      <Pane ref={sitesSectionRef} className={sharedClasses.section}>
        {sitesSet.map(site => {
          if (!site.dummyObj) {
            return (
              <Pane
                key={site.id}
                data-testid={`site-${_.kebabCase(site.name)}`}
              >
                <Table className={sharedClasses.table}>
                  <Table.Head className={sharedClasses.tableHeader}>
                    <Table.TextHeaderCell data-testid="site-name">
                    <span className={sharedClasses.tableHeaderTitle}>{site.name}</span>
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell textAlign="right">
                      <Button
                        className={sharedClasses.buttonStyles}
                        marginRight={0}
                        onClick={() => handleOpenSideSheet(site.id)}
                      >
                        View site
                      </Button>
                    </Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body
                    height={100}
                    backgroundColor="#ffffff"
                    borderRadius="6px"
                  >
                    <Table.Row borderColor="#ffffff">
                      <Table.TextCell
                        className={sharedClasses.elementSelector}
                        onClick={() => handleOpenSideSheet(site.id)}
                        data-testid={`${site.id}-studies-count`}
                      >
                        {site.Study_Sites_aggregate && (
                          <>
                            {site.Study_Sites_aggregate.aggregate.count} Studies
                          </>
                        )}
                      </Table.TextCell>
                      <Table.TextCell
                        className={sharedClasses.elementSelector}
                        onClick={() => handleOpenSideSheet(site.id)}
                        data-testid={`${site.id}-users-count`}
                      >
                        {site.Site_Users_aggregate && (
                          <>{site.Site_Users_aggregate.aggregate.count} Users</>
                        )}
                      </Table.TextCell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Pane>
            )
          }
        })}
      </Pane>

      {showSiteDetails && selectedSite && (
        <SiteDetails
          siteId={selectedSite.id}
          show={showSiteDetails}
          handleDetailsClose={handleSiteDetailsClose}
          parentSectionRef={sitesSectionRef}
          siteNames={sitesSet?.map(site => site.name)}
          canEdit={canEditSite}
          canOpenUserDetails={canOpenUserDetails}
        />
      )}
      {showCreateSiteDialog && (
        <CreateSite
          sitesNames={sitesSet?.map(site => site.name)}
          showModal={showCreateSiteDialog}
          handleCloseModal={handleCreateSiteCloseModal}
          onSiteCreated={onSiteCreation}
        />
      )}
    </>
  )
}

export default SitesPage
